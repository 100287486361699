import React from "react";
import {Table, Row, Col, Card} from "react-bootstrap";
import moment from "moment";
import { FaCheckDouble } from "react-icons/fa";
import { ImCross } from "react-icons/im";

const TrainingMessages = (props) => {
    const {messages, surferSelected} = props;
    let receiver = [];
    if (surferSelected !== null && messages.length !== 0) {
        receiver = messages.filter((message) => message.receiver === surferSelected.uid && message.heatId === surferSelected.heatId);
        receiver.sort((a, b) => {   
            return new Date(b.created) - new Date(a.created);
        });
    }

    return (
        <>
            {surferSelected && receiver.length !== 0 &&
                <Card className="pt-3">
                    <Card.Header>Messages</Card.Header>
                    <Card.Body>
                        <Row>
                            <Col xs={12} md={12}>
                                <Table striped hover size="sm" responsive>
                                    <thead>
                                    <tr>
                                        <th>Time</th>
                                        <th>Message</th>
                                        <th>Read</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {receiver.map((message) => (
                                        <tr key={message.messageId}>
                                            <td>{moment(message.created).format(`hh:mm a`)}</td>
                                            <td>
                                                {message.message}
                                            </td>
                                            <td>
                                                {message.read ? <FaCheckDouble/> : <ImCross/>}
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            }
        </>
    );
}
export default TrainingMessages;