import React, {useEffect, useState} from 'react';
import {withFirebase} from "../Firebase";
import {Container, Row, Col} from "react-bootstrap";
import * as ROUTES from '../../constants/routes';
import GetWinner from "./training-get-winner";
import {compose} from "recompose";
import {withAuthorization, withEmailVerification} from "../Session";
import TrainingDetails from "./training-details";
import PastHeats from "./training-past-heats";
import SurferSelected from "./training-surfer-selected";
import TrainingCountdown from "./training-countdown";
import Loading from "../Views/loading";
import {Link, useNavigate} from "react-router-dom";
import {onValue, query, orderByChild, equalTo} from "firebase/database";
import TrainingMessages from "./training-messages";

const DashboardTraining = (props) => {
    const navigate = useNavigate();
    const [trainingId] = useState(props.router.params.id);
    const [loading, setLoading] = useState(true);
    const [running, setRunning] = useState(false);
    const [finish, setFinish] = useState(false);
    const [currentHeat, setCurrentHeat] = useState(null);
    const [showCountDown, setShowCountDown] = useState(false);
    const [surferSelected, setSurferSelected] = useState(null);
    const [surferSelectedView, setSurferSelectedView] = useState(false);
    const [surfersWaves, setSurfersWaves] = useState([]);
    const [training, setTraining] = useState([]);
    const [heats, setHeats] = useState([]);
    const [isNewPriorityLoading, setIsNewPriorityLoading] = useState(false);
    const [currentPriority, setCurrentPriority] = useState([]);
    const [currentUserUid] = useState(props.firebase.currentSurferData().uid);
    const [currentUserRole] = useState(props.firebase.currentSurferData().role);
    // Training.
    const [isNewHeatLoading, setIsNewHeatLoading] = useState(false);
    const [heatCreated, setHeatCreated] = useState(null);
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [heatId, setHeatId] = useState(null);
    // callbacks
    const [saveHeatCallback, setSaveHeatCallback] = useState(false);
    const [saveWaveCallback, setSaveWaveCallback] = useState(false);
    // Wave.
    const [isNewWaveLoading, setIsNewWaveLoading] = useState(false);
    const [waveId, setWaveId] = useState(null);
    const [waveCreated, setWaveCreated] = useState(null);
    const [currentWave, setCurrentWave] = useState(null);
    // Score.
    const [isNewScoreLoading, setIsNewScoreLoading] = useState(false);
    const [score, setScore] = useState(5);
    const [note, setNote] = useState('');
    // Message.
    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState('');
    const firebaseRefHeats = props.firebase.heats();
    const firebaseRefWaves = props.firebase.waves();
    const firebaseRefScores = props.firebase.scores();
    const firebaseRefMessages = props.firebase.messages();

    // useEffect first time.
    useEffect(() => {
        getTraining();
        onValue(query(firebaseRefHeats, orderByChild('trainingId'), equalTo(trainingId)), onFirebaseHeatsChanged);
        onValue(query(firebaseRefScores, orderByChild('trainingId'), equalTo(trainingId)), onFirebaseWavesChanged);
        onValue(query(firebaseRefWaves, orderByChild('trainingId'), equalTo(trainingId)), onFirebaseWavesChanged);
        onValue(query(firebaseRefMessages, orderByChild('trainingId'), equalTo(trainingId)), onFirebaseMessagesChanged);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // useEffect when heats changes.
    useEffect(() => {
        getCurrentHeat();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [heats]);

    // useEffect when anything on heat changes.
    useEffect(() => {
        saveHeat();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saveHeatCallback]);

    // useEffect when anything on wave changes.
    useEffect(() => {
        setWave();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saveWaveCallback]);

    const onFirebaseHeatsChanged = () => {
        getHeats(trainingId);
    };

    const onFirebaseWavesChanged = () => {
        getWaves(trainingId);
    };

    const onFirebaseMessagesChanged = () => {
        getMessages(trainingId);
    };

    const getTraining = () => {
        const data = ({trainingId: trainingId, surferRole: currentUserRole});
        const getTrainingById = props.firebase.cloud('getTrainingById');
        getTrainingById(data).then(result => {
            const training = result.data;
            if (training) {
                setTraining(training);
            } else {
                navigate(ROUTES.DASHBOARD);
            }
        });
        setLoading(false);
    }

    const getHeats = (trainingId) => {
        if (trainingId) {
            const getHeats = props.firebase.cloud('getHeats');
            getHeats({trainingId: trainingId}).then(result => {
                if (result.data) {
                    setHeats(result.data);
                } else {
                    setHeats(heats);
                }
            });
            setLoading(false);
        }
    }

    const getCurrentHeat = () => {
        if (heats.length > 0) {
            const getCurrentHeat = props.firebase.cloud('getCurrentHeat');
            getCurrentHeat({heats: heats}).then(result => {
                const data = result.data;
                if (data) {
                    setShowCountDown(true);
                    setCurrentHeat(data.currentHeat);
                    setHeatId(data.currentHeat.key);
                    setRunning(data.currentHeat.heat.running);
                    setStartTime(data.currentHeat.heat.startTime);
                    setEndTime(data.currentHeat.heat.endTime);
                    setHeatCreated(data.currentHeat.heat.created);
                    setFinish(data.currentHeat.heat.finished);
                } else {
                    setFinish(true);
                }
            });
        }
    }

    const saveHeat = () => {
        if (saveHeatCallback) {
            let heat = {
                trainingTime: training.trainingTime * 60000,
                created: heatCreated,
                running: running,
                startTime: startTime,
                endTime: endTime,
                heatId: heatId,
                trainingId: trainingId,
                priority: currentPriority,
                finished: finish
            }
            const saveHeat = props.firebase.cloud('saveHeat');
            saveHeat(heat).then(result => {
                setSaveHeatCallback(false);
                setIsNewHeatLoading(false);
                setIsNewPriorityLoading(false);
            });
        }
    }

    const handleNewHeat = (event) => {
        setIsNewHeatLoading(true);
        setHeatId(props.firebase.createHeat());
        setHeatCreated(Date.now());
        setSaveHeatCallback(true);
        // Make sure to unset previous heat.
        setSurfersWaves([]);
        setRunning(false);
        setStartTime(null);
        setEndTime(null);
        setCurrentPriority([]);
        setCurrentHeat([]);
        setSurferSelectedView(false);
        setFinish(false);
        setMessages(false);
        event.preventDefault();
    }

    const handleStartHeat = (event) => {
        setRunning(true);
        setStartTime(Date.now());
        setSaveHeatCallback(true);
        event.preventDefault();
    }

    const handleFinishHeat = () => {
        setRunning(false);
        setEndTime(Date.now());
        setFinish(true);
        setSaveHeatCallback(true);
    }

    const handleSetPriority = (uid, heatId) => {
        setIsNewPriorityLoading(true);
        setCurrentPriority(uid);
        setSaveHeatCallback(true);
    }

    const displaySurferSelected = (uid, heatId) => {
        if (uid && heatId) {
            setSurferSelected({
                data: training.surfers[uid],
                uid: uid,
                heatId: heatId
            });
            setSurferSelectedView(true);
        }
    }

    const handleSeeCompetitor = (uid, heatId) => {
        setSurferSelectedView(false);
        getMessages(trainingId)
        displaySurferSelected(uid, heatId);
    }

    const getWaves = (trainingId) => {
        if (trainingId) {
            const surferSelectedWaves = query(firebaseRefWaves, orderByChild('trainingId'), equalTo(trainingId));
            let surfersWavesCollection = [];
            setScore(5);
            onValue(surferSelectedWaves, snapshot => {
                const surfersWavesObject = snapshot.val();
                if (surfersWavesObject) {
                    Object.keys(surfersWavesObject).forEach(key => {
                        surfersWavesCollection.push(surfersWavesObject[key]);
                    });
                    setSurfersWaves(surfersWavesCollection);
                }
            });
        }
    }

    const handleAddWave = (event) => {
        setIsNewWaveLoading(true);
        setWaveId(props.firebase.createWave());
        setWaveCreated(Date.now());
        setSaveWaveCallback(true);
        event.preventDefault();
    }

    const setWave = () => {
        if (saveWaveCallback) {
            const wave = {
                heatId: currentHeat.key,
                waveId: waveId,
                author: currentUserUid,
                surfer: surferSelected.uid,
                created: waveCreated,
                trainingId: trainingId
            }
            setCurrentWave(waveId);
            const saveWave = props.firebase.cloud('saveWave');
            saveWave(wave).then(() => {
                setIsNewWaveLoading(false);
            });
            setSaveWaveCallback(false);
        }
    }

    const saveScore = (waveScored) => {
        setIsNewScoreLoading(true);
        if (waveScored) {
            const scoreId = props.firebase.createScore();
            let newScore = {
                scoreId: scoreId,
                score: score,
                note: note,
                waveId: waveScored,
                surfer: surferSelected.uid,
                judge: currentUserUid,
                created: Date.now(),
                trainingId: trainingId,
                priority: currentPriority,
                heatId: heatId
            }
            const saveScore = props.firebase.cloud('saveScore');
            saveScore(newScore).then(() => {
                setNote('');
                setIsNewScoreLoading(false);
            });
        }
    }

    const changeScore = (value) => {
        setScore(value);
        setCurrentWave(currentWave);
    }

    const handleChangeNote = (event) => {
        setNote(event.target.value);
    }

    const handleDeleteHeat = (heatId) => {
        if (heatId) {
            const deleteHeat = props.firebase.cloud('deleteHeat');
            deleteHeat({heatId: heatId}).then(() => {
                setRunning(false);
                setCurrentHeat(false);
                setSurferSelectedView(false);
                setShowCountDown(false);
                setEndTime(false);
                setMessages(false);
            });
        }
    }

    const handleChangeMessage = (event) => {
        setMessage(event.target.value);
    }

    const sendMessage = (surfer) => {
        const newMessage = props.firebase.createMessage();
        let data = {
            messageId: newMessage,
            trainingId: trainingId,
            heatId: heatId,
            message: message,
            receiver: surfer,
            type: 'default',
        }
        const saveMessage = props.firebase.cloud('saveMessage');
        saveMessage(data).then(() => {
            getMessages(surfer, heatId);
        });
    }

    const getMessages = (trainingId) => {
        if (trainingId) {
            const getMessages = props.firebase.cloud('getMessages');
            getMessages({trainingId: trainingId}).then((result) => {
                setMessages(result.data);
            });
        }
    }

    const permissions = training.length !== 0 && (currentUserUid === training.author || currentUserUid in training.judges);
    const renderer = ({formatted: {minutes, seconds}, completed}) => {
        completed = finish ? finish : completed;
        if (completed) {
            return <GetWinner training={training} heat={currentHeat}/>;
        } else {
            return (
                <>{minutes}:{seconds}</>
            );
        }
    };
    return (
        <Container className="content p-2 h-100">
            {loading && <Loading/>}
            {training && training.author &&
                <Row>
                    <Link to={`/dashboard/training/${trainingId}/statistics`}>Go to statistics</Link>
                    <Col xs={12} md={3}>
                        <TrainingCountdown training={training} showCountDown={showCountDown} endTime={endTime}
                                           currentHeat={currentHeat} isNewHeatLoading={isNewHeatLoading}
                                           permissions={permissions} running={running}
                                           heatFinished={finish} renderer={renderer}
                                           handleNewHeat={handleNewHeat} handleFinishHeat={handleFinishHeat}
                                           handleStartHeat={handleStartHeat} handleSeeCompetitor={handleSeeCompetitor}/>
                        {messages && messages.length > 0 &&
                            <TrainingMessages messages={messages} surferSelected={surferSelected} />
                        }
                    </Col>
                    <Col xs={12} md={6}>
                        <SurferSelected surferSelectedView={surferSelectedView} training={training}
                                        permissions={permissions} surferSelected={surferSelected} running={running}
                                        surfersWaves={surfersWaves} currentUserUid={currentUserUid} score={score}
                                        currentHeat={currentHeat} isNewWaveLoading={isNewWaveLoading}
                                        saveScore={saveScore} changeScore={changeScore} handleAddWave={handleAddWave}
                                        handleSetPriority={handleSetPriority} isNewScoreLoading={isNewScoreLoading}
                                        isNewPriorityLoading={isNewPriorityLoading} handleChangeNote={handleChangeNote}
                                        note={note} handleChangeMessage={handleChangeMessage} sendMessage={sendMessage}
                                        message={message} />
                    </Col>
                    <Col xs={12} md={3}>
                        <TrainingDetails training={training}/>
                        <PastHeats training={training} heats={heats} permissions={permissions}
                                   handleSeeCompetitor={handleSeeCompetitor} handleDeleteHeat={handleDeleteHeat}/>
                    </Col>
                </Row>
            }
        </Container>
    );
}

const condition = authUser => !!authUser;

export default compose(
    withEmailVerification,
    withAuthorization(condition),
    withFirebase
)(DashboardTraining);