import React, {useState} from 'react';
import {Modal, Button, Form} from "react-bootstrap";
import {IoMdCloseCircle} from "react-icons/io";

function MyVerticallyCenteredModal(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Send message
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Control
                    value={props.message}
                    onChange={value => props.onChange(value)}
                    as="textarea"
                    maxLength="89"
                    placeholder="Type something..."/>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide} variant="info"><IoMdCloseCircle/> Close</Button>
                <Button data={props.data} variant="primary"
                        onClick={() => {
                            props.onSubmit();
                            props.onHide();
                        }}>
                    {props.name}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export var ModalMessage = (props) => {
    const [modalShow, setModalShow] = useState(false);
    const {handle, name, data, sendMessage, submit, className} = props
    return (
        <>
            <Button variant="info" className={className} onClick={() => setModalShow(true)}>
                {name}
            </Button>

            <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                onChange={handle}
                name={name}
                data={data}
                message={sendMessage}
                onSubmit={submit}
            />
        </>
    );
};