import React, {useEffect, useState} from "react";
import {Button, Card, Col, ListGroup, Row, Spinner, Table} from "react-bootstrap";
import SurferCard from "../Surfer/surfer-card";
import {GrAddCircle, GrScorecard} from "react-icons/gr";
import {BsFillPersonCheckFill} from "react-icons/bs";
import {TiMessages} from "react-icons/ti";
import GetSurfer from "../Surfer/get-surfer";
import moment from "moment";
import GetScore from "./training-get-score";
import GetScoreTotal from "./training-get-score-total";
import TrainingAddScore from "./training-add-score";
import {ModalMessage} from "../Modal/modal-message";

const CreateWaveButton = () => {
    return (
        <>
            <GrAddCircle/> Wave
        </>
    )
}

const SelectPriorityButton = () => {
    return (
        <>
            <BsFillPersonCheckFill/> Priority
        </>
    )
}

const SurferSelected = (props) => {
    const {
        surferSelectedView, training, permissions, isNewWaveLoading, isNewPriorityLoading,
        surferSelected, running, handleAddWave, handleSetPriority, isNewScoreLoading,
        currentHeat, surfersWaves, currentUserUid, score, changeScore, saveScore,
        handleChangeNote, note, handleChangeMessage, sendMessage, message
    } = props;
    const [surferWaves, setSurferWaves] = useState([]);
    const [active, setActive] = useState(false);

    useEffect(() => {
        if (surferSelectedView && currentHeat && currentHeat.key === surferSelected.heatId) {
            setActive(true);
        } else {
            setActive(false);
        }
    }, [surferSelectedView, currentHeat, surferSelected]);

    useEffect(() => {
        if (surferSelected && surfersWaves.length !== 0) {
            const surferWaves = surfersWaves.filter(wave => (wave.surfer === surferSelected.uid && wave.heatId === surferSelected.heatId));
            setSurferWaves(surferWaves);
        } else {
            setSurferWaves([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [surferSelected, surfersWaves]);

    return (
        <Card className="pt-3">
            <Card.Header>Surfer</Card.Header>
            <Card.Body>
                {surferSelectedView &&
                    <ListGroup variant="flush">
                        <Row>
                            <Col>
                                <SurferCard uid={surferSelected.uid}
                                            surfer={surferSelected.data}
                                            footer={false}
                                            displayUid={false}/>
                            </Col>
                            <Col className="d-flex align-items-center">
                                {permissions &&
                                    <Row>
                                        <Col xs={12} md={12}>
                                            {surferSelected.data.bot === false &&
                                                <ModalMessage data={surferSelected.uid}
                                                              message={message}
                                                              name={<span><TiMessages/> Message</span>}
                                                              handle={handleChangeMessage}
                                                              submit={e => sendMessage(surferSelected.uid)}
                                                              className="mb-3 button-actions-surfer"/>
                                            }
                                        </Col>
                                        <Col xs={12} md={12}>
                                            <Button variant="primary"
                                                    className="mb-3 button-actions-surfer"
                                                    disabled={!running || isNewWaveLoading || !active}
                                                    onClick={handleAddWave}>
                                                {isNewWaveLoading ? <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                    : <CreateWaveButton/>}
                                                {isNewWaveLoading && <span> Adding</span>}
                                            </Button>
                                        </Col>
                                        <Col xs={12} md={12}>
                                            <Button variant="dark"
                                                    className="mb-3 button-actions-surfer"
                                                    disabled={!running || isNewPriorityLoading || !active}
                                                    onClick={e => handleSetPriority(surferSelected.uid, currentHeat.key)}>
                                                {isNewPriorityLoading ? <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                    : <SelectPriorityButton/>}
                                                {isNewPriorityLoading && <span> Adding</span>}
                                            </Button>
                                        </Col>
                                    </Row>
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={12}>
                                <Table striped hover size="sm" responsive>
                                    <caption>Number of waves: {surferWaves.length}</caption>
                                    <thead>
                                    <tr>
                                        <th>Time</th>
                                        {training && training.judges && Object.keys(training.judges).map((uid) => (
                                            <th key={uid}><GetSurfer uid={uid}/></th>
                                        ))}
                                        <th>Total</th>
                                        <th>Score</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {surferWaves.length !== 0 && surferWaves.map((wave) => (
                                        <tr key={wave.waveId}>
                                            <td>{moment(wave.created).format(`hh:mm a`)}</td>
                                            {training && training.judges && Object.keys(training.judges).map((uid) => (
                                                <td key={uid}>
                                                    <GetScore judge={uid} waveId={wave.waveId}
                                                              surfer={surferSelected.uid}
                                                              surferWaves={surferWaves}/>
                                                </td>
                                            ))}
                                            <td>
                                                <GetScoreTotal waveId={wave.waveId}
                                                               surfer={surferSelected.uid}
                                                               surferWaves={surferWaves}/>
                                            </td>
                                            <td>
                                                <TrainingAddScore waveId={wave.waveId}
                                                                  name={<GrScorecard/>}
                                                                  judge={currentUserUid}
                                                                  score={score}
                                                                  active={active}
                                                                  running={running}
                                                                  changeScore={changeScore}
                                                                  handleChangeNote={handleChangeNote}
                                                                  note={note}
                                                                  isNewScoreLoading={isNewScoreLoading}
                                                                  saveScore={e => saveScore(wave.waveId)}/>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </ListGroup>
                }
            </Card.Body>
        </Card>
    );
}

export default SurferSelected;